@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto+Mono&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Mono", monospace;
}

html,body {
    background-color: black;
    height: 100%;
    margin: 0;
    padding: 0;
}

.navbar-text-animation:hover {
    color: #FF0000;
}

.navbar-text-animation::after {
    content: "";
    display: block;
    height: 3px;
    background: whitesmoke;
    width: 0%;
    transition: all ease-in-out 300ms;
}

.navbar-text-animation:hover::after {
    width: 100%;
}

.welcome-text,
.secondary-text {
    font-family: "Inter", "sans-serif";
}

.animate-character {
    text-transform: uppercase;
    background-image: linear-gradient(
        -45deg,
        #D7D7D7,
        #FF0000, 
        #FFFFFF, 
        #FF0000  
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 5s linear infinite;
    display: inline-block;
}

/* Keyframes für die Animation */
@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.white-shadow {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.site-footer {
    
}
